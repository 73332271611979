// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-privacidad-index-js": () => import("./../../../src/pages/aviso-privacidad/index.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-index-js" */),
  "component---src-pages-categorias-index-js": () => import("./../../../src/pages/categorias/index.js" /* webpackChunkName: "component---src-pages-categorias-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-garantia-index-js": () => import("./../../../src/pages/garantia/index.js" /* webpackChunkName: "component---src-pages-garantia-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-index-js": () => import("./../../../src/pages/proyectos/index.js" /* webpackChunkName: "component---src-pages-proyectos-index-js" */)
}

